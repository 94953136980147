@import '../../style-variables-colors.scss';

.c-FileInput {
  display: flex;
  width: 100%;

  > .input {
    display: none;
    width: 100%;
  }

  > .label {
    padding: 3px 5px;
    background: $white;
    color: $main-blue;
    border: 1px solid $main-blue;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
    text-align: center;
  }
}

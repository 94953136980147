@import "@clientCommon/style-variables-colors.scss";

$block-separation-margin: 20px;

.c-PatientCaptureView {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;

  >.files-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .c-FileInputPreview {
      margin-bottom: $block-separation-margin;
    }
  }

  >.submit-container {
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    width: 100%;
    padding-top: $block-separation-margin;
    margin-top: $block-separation-margin;
    border-top: 1px solid $light-grey;

    >.submit-btn {
      border: none;
      padding: 5px 10px;
      color: $white;
      background-color: $main-blue;
      border-radius: 3px;
      outline: none;
      cursor: pointer;
      font-size: 20px;
      width: 180px;
      height: 50px;
      margin-right: 20px;

      &:disabled {
        background-color: $light-grey;
        pointer-events: none;
      }
    }

    >.success-text, >.error-text {
      font-size: 20px;
      margin-top: 5px;
    }

    >.succeess-text {
      color: $green;
    }
  
    >.error-text {
      color: $dark-red;
    }
  }
}
@import '@clientCommon/style-variables-colors.scss';

.c-FormField {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;

  > .input,
  > .input-required {
    border: none;
    font-size: 18px;
    padding: 5px;
    width: 95%;
    outline: none;
    border-radius: 3px;
    margin-right: 10px;
    border-radius: 3px;
    background: $white;
    border: 1px solid $main-blue;
  }

  > .input-required {
    border-color: $dark-red;
  }

  > .input-label {
    color: $grey;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 7px;
  }
}

$navy: #375362;
$dark-blue: #1a272e;
$gold: #fdbf21;
$charcoal: #0a1612;
$darker-light-grey: #c6c7c8;
$light-grey: #e6e7e8;
$transparent-grey: #f8f8f9;
$darker-grey: #989ea1;
$off-white: #f8f9f9;
$white: #ffffff;
$light-blue: #1183c0;
$pale-blue: #b4def9;

$turquoise: #13c7af;
$pink-red: #e83152;
$dark-red: #cf6059;
$green: #07a567;
$lighter-green: #59cf96;

$text-primary: #19272e;
$text-light-grey: #9298a0;
$text-mid-grey: #71777a;
$text-dark-grey: #333333;

$border-light: #ebebeb;
$border-mid: #e0e0e0;

$main-blue: #59a3cf;
$grey: #808080;
$red: #ff0000;
$black: #000000;
$dark-grey: #404040;
$spec-white: #f2f6f8;
$white-grey: #f5f5f5;
$white-grey-light: #fcfcfc;
$input-shadow-from: rgba(0, 0, 0, 0.24);
$input-shadow-to: rgba(0, 0, 0, 0.1);

@import '@clientCommon/style-variables-colors.scss';

.c-TagsInput {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  width: 100%;
  margin-bottom: 20px;

  > .tags {
    display: flex;
    flex: 0 1 auto;
    width: 100%;

    > .selected {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      > .tag {
        background: $dark-red;
        border-radius: 3px;
        color: $white;
        padding: 5px 10px;
        margin-right: 10px;
        margin-bottom: 10px;

        > .tag-remove-icon {
          margin-left: 10px;
          color: $white;
          background: $dark-red;
          border: none;
          cursor: pointer;
        }
      }

      > .tag-input-container {
        display: flex;
        width: 100%;

        > .tag-input {
          width: 100%;
          height: 25px;
          padding: 5px;
          font-size: 18px;
          border-radius: 5px;
          border: 1px solid $light-grey;
        }
      }
    }
  }

  > .label {
    color: $grey;
    font-weight: 700;
    margin-top: 7px;
    text-transform: uppercase;
  }
}

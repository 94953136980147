@import '@clientCommon/style-variables-colors.scss';

$border-radius: 1px;
$border-color: $border-mid;
$required-border-color: $dark-red;
$container-padding: 10px;

.c-FileInputPreview,
.c-FileInputPreview-required {
  display: flex;
  flex-direction: column;
  border: $border-radius solid $border-color;
  padding: $container-padding;
  border-radius: 3px;

  > .preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: $border-radius solid $border-color;
    padding-bottom: $container-padding;
    margin-bottom: 10px;

    @media screen and (min-width: 0) {
      height: 200px;
    }

    @media screen and (min-width: 480px) {
      height: 300px;
    }

    > .preview-img-container {
      position: relative;
      height: 100%;
      width: 100%;

      > .preview-img {
        width: 100%;
        height: 100%;
        border: none;
      }

      > .delete-img-icon {
        position: absolute;
        cursor: pointer;
        top: -5px;
        right: -5px;
        width: 50px;
        height: 50px;
      }
    }

    > .preview-text {
      color: $grey;
      font-weight: 600;
      text-transform: uppercase;

      @media screen and (min-width: 0) {
        font-size: 20px;
      }

      @media screen and (min-width: 480px) {
        font-size: 28px;
      }
    }
  }
}

.c-FileInputPreview-required {
  border: $border-radius solid $required-border-color;
}

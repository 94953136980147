@import '../../style-variables-colors.scss';

.c-ContentScreen {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  .spinner {
    background-color: $white;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    flex: 1 0 auto;
    z-index: 1000;

    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */

    opacity: 0;

    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;

    &.active {
      opacity: 0.8;
    }

    img {
      transition: opacity 0.1s;
    }
  }
}

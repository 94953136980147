@import "@clientCommon/style-variables-colors.scss";

.c-NoHealthcareSite {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  text-align: center;

  >.no-healthcare-site-text {
    color: $dark-red;
  }
}
